
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  emits: [
    'final-step'
  ],
  setup (context, { emit }) {
    // const redirectTime = 5000

    const finishWizard = (routeName: string) => {
      emit('final-step', routeName)
    }

    onMounted(() => {
      // setTimeout(() => {
      //   finishWizard()
      // }, redirectTime)
    })

    return {
      finishWizard
    }
  }
})
