<template>
  <div class="flex flex-col w-full">
    <div class="flex flex-col md:flex-row justify-items-center gap-4 w-full">
      <div class="card card-hover cursor-pointer w-full">
        <div class="card-body flex flex-col justify-between items-center pt-4 text-center">
          <img src="@/assets/img/go-to-config.jpg" alt="done" class="w-full h-64"/>
          <span class="font-semibold">
            Quero ajustar a minha identidade visual com logotipo e templates de imagem agora.
          </span>
          <div class="flex justify-center w-full mt-4">
            <button
              @click.prevent="finishWizard('settings-profile')"
              class="btn btn-primary w-full"
            >
              Ajustar identidade visual
            </button>
          </div>
        </div>
      </div>
      
      <div class="card card-hover cursor-pointer w-full">
        <div class="card-body flex flex-col justify-between items-center pt-4 text-center">
          <img src="@/assets/img/start-using.jpg" alt="done" class="w-full h-64"/>
          <span class="font-semibold">
            Quero ajustar a minha identidade visual com logotipo e templates de imagem mais tarde.
          </span>
          <div class="flex justify-center w-full mt-4">
            <button
              @click.prevent="finishWizard('home')"
              class="btn btn-primary w-full"
            >
              {{ $t('action.finish') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  emits: [
    'final-step'
  ],
  setup (context, { emit }) {
    // const redirectTime = 5000

    const finishWizard = (routeName: string) => {
      emit('final-step', routeName)
    }

    onMounted(() => {
      // setTimeout(() => {
      //   finishWizard()
      // }, redirectTime)
    })

    return {
      finishWizard
    }
  }
})
</script>
